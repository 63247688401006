import { z } from "zod"

import { ISODateTimeSchema } from "../../infra/gateways/ISODateTime"

const objectionExtractSchema = z.object({
	startsAtMs: z.number(),
	endsAtMs: z.number(),
	content: z.string(),
	attendeeName: z.string(),
	callId: z.string(),
	callName: z.string(),
})

export const objectionBadgeSchema = z.enum(["new", "frequency-increase"])
export type ObjectionBadge = z.infer<typeof objectionBadgeSchema>

export const objectionReportSectionSchema = z.object({
	title: z.string(),
	description: z.string(),
	responseAnalysis: z.string(),
	recommendations: z.array(z.string()),
	riskLevel: z.enum(["low", "medium", "high"]),
	badges: z.array(objectionBadgeSchema),
	extracts: z.array(objectionExtractSchema).nullish(),
	callsCount: z
		.object({
			last7Days: z.number(),
			olderThan7Days: z.number(),
			percentIncrease: z.number(),
		})
		.nullish(),
})

export const objectionReportSchema = z.object({
	id: z.string(),
	createdAt: ISODateTimeSchema,
	sections: z.array(objectionReportSectionSchema),
	teamId: z.string().nullish(),
	callsCount: z.number(),
})
export type ObjectionReport = z.infer<typeof objectionReportSchema>

export type ObjectionReportSection = z.infer<typeof objectionReportSectionSchema>

export type ObjectionExtract = z.infer<typeof objectionExtractSchema>

export type GetAllReportsFilter = {
	teamId?: string
}

export const getAllReportsResponseSchema = z.array(objectionReportSchema)

export type IObjectionsGateway = {
	getAllReports(filter?: GetAllReportsFilter): Promise<ObjectionReport[]>
}
