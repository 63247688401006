import React from "react"
import { toast } from "react-toastify"

import type { LinkedinIdentity } from "../../../../core/application/gateways/platform-admin.gateway"
import { Badge } from "../../../components/design-system/Badge.component"
import { Button } from "../../../components/design-system/Button.component"
import { Input } from "../../../components/design-system/Input"
import { Modal } from "../../../components/design-system/Modal.component"
import { Tooltip } from "../../../components/design-system/Tooltip.component"
import { AdminLayout } from "../../../components/shared/AdminLayout/AdminLayout.component"
import { useDependencies } from "../../../contexts/dependencies.context"

function useCreateLinkedinIdentity() {
	const [description, setDescription] = React.useState("")
	const [userAgent, setUserAgent] = React.useState("")
	const [cookies, setCookies] = React.useState("")
	const { platformAdminGateway } = useDependencies()

	const createLinkedinIdentity = async () => {
		await platformAdminGateway.createIdentity({
			description,
			userAgent,
			cookies: JSON.parse(cookies),
		})
	}

	const canSubmit = Boolean(description && userAgent && cookies)

	const reset = React.useCallback(() => {
		setDescription("")
		setUserAgent("")
		setCookies("")
	}, [])

	return {
		reset,
		canSubmit,
		description,
		setDescription,
		userAgent,
		setUserAgent,
		cookies,
		setCookies,
		createLinkedinIdentity,
	}
}

export function LinkedinIdentitiesPage() {
	const [identities, setIdentities] = React.useState<LinkedinIdentity[]>([])
	const { platformAdminGateway } = useDependencies()
	const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false)
	const {
		description,
		setDescription,
		userAgent,
		setUserAgent,
		cookies,
		setCookies,
		createLinkedinIdentity,
		canSubmit,
		reset,
	} = useCreateLinkedinIdentity()

	const handleSubmit = React.useCallback(async () => {
		try {
			await createLinkedinIdentity()
			toast("Identity created", {
				type: "success",
			})
			platformAdminGateway.getLinkedinIdentities().then(setIdentities)
			setIsCreateModalOpen(false)
		} catch (e) {
			toast("Failed to create identity", {
				type: "error",
			})
		}
	}, [createLinkedinIdentity, platformAdminGateway])

	const handleDeleteIdentity = React.useCallback(
		async (id: string) => {
			if (!window.confirm("Are you sure you want to delete this identity?")) {
				return
			}

			try {
				await platformAdminGateway.deleteIdentity(id)
				toast("Identity deleted", {
					type: "success",
				})
				platformAdminGateway.getLinkedinIdentities().then(setIdentities)
			} catch (e) {
				toast("Failed to delete identity", {
					type: "error",
				})
			}
		},
		[platformAdminGateway],
	)

	React.useEffect(() => {
		platformAdminGateway.getLinkedinIdentities().then(setIdentities)
	}, [platformAdminGateway])

	React.useEffect(() => {
		reset()
	}, [isCreateModalOpen, reset])

	const sortedIdentities = React.useMemo(() => {
		return identities.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
	}, [identities])

	return (
		<AdminLayout origin="linkedin-identities" pageName="Back office: LinkedIn identities list">
			<Modal open={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
				<form
					onSubmit={(e) => {
						e.preventDefault()
						handleSubmit()
					}}
				>
					<Input.Group>
						<Input.Label>Identity description</Input.Label>
						<Input.Input
							type="text"
							placeholder="Description"
							value={description}
							onChange={(e) => setDescription(e.target.value)}
						/>
					</Input.Group>
					<Input.Group>
						<Input.Label>User agent</Input.Label>

						<div className="flex items-center gap-2 mt-2">
							<Input.Input
								type="text"
								placeholder="User agent"
								value={userAgent}
								onChange={(e) => setUserAgent(e.target.value)}
								className="flex-1 !mt-0"
							/>
							<Button
								eventName={"Generate user agent clicked"}
								variant="default"
								size="md"
								onClick={() => setUserAgent(navigator.userAgent)}
							>
								Use current user agent
							</Button>
						</div>

						<Input.Helper>
							This should be the user agent of the browser that was used to login with this account.
						</Input.Helper>
					</Input.Group>
					<Input.Group>
						<Input.Label>Cookies</Input.Label>
						<Input.TextArea
							placeholder="Cookies"
							value={cookies}
							onChange={(e) => setCookies(e.target.value)}
						/>
						<Input.Helper>
							To retrieve your cookies, install the "EditThisCookie" Chrome extension, navigate to
							LinkedIn while being logged in, open the "EditThisCookie" menu and click "Export".
						</Input.Helper>
					</Input.Group>
					<Button
						className="mt-4"
						eventName="Create linkedin identity clicked"
						variant="primary"
						type="submit"
						disabled={!canSubmit}
					>
						Create
					</Button>
				</form>
			</Modal>

			<div className="px-4 sm:px-6 lg:px-8">
				<div className="sm:flex sm:items-center">
					<div className="sm:flex-auto">
						<h1 className="text-base font-semibold text-gray-900">LinkedIn Identities</h1>
						<p className="mt-2 text-sm text-gray-700">
							All linkedin identities that are currently in use by Rippletide.
						</p>
					</div>
					<div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
						<Button
							eventName={"Create identity clicked"}
							variant="primary"
							onClick={() => setIsCreateModalOpen(true)}
						>
							Create identity
						</Button>
					</div>
				</div>
				<div className="mt-8 flow-root">
					<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
						<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
							<table className="min-w-full divide-y divide-gray-300">
								<thead>
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
										>
											Description
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											User agent
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Requests
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Created
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											Valid
										</th>
										<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
											<span className="sr-only">Edit</span>
										</th>
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200">
									{sortedIdentities?.map((identity) => (
										<tr key={identity.id}>
											<td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
												{identity.description}
											</td>
											<td
												title={identity.userAgent}
												className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-[200px] truncate"
											>
												{identity.userAgent}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{identity.requestCount}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{identity.createdAt.toLocaleString()}
											</td>
											<td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
												{identity.invalidatedAt ? (
													<Tooltip
														content={`Banned: ${identity.invalidatedAt.toLocaleString()}`}
													>
														<Badge label="Banned" variant="error" />
													</Tooltip>
												) : (
													<Badge label="Valid" variant="success" />
												)}
											</td>
											<td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
												<Button
													onClick={() => handleDeleteIdentity(identity.id)}
													eventName="Delete identity clicked"
													variant="danger"
													size="xs"
												>
													Delete
												</Button>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</AdminLayout>
	)
}
