import React from "react"

import { Duration } from "../../../../components/design-system/Duration.component"
import { Modal } from "../../../../components/design-system/Modal.component"
import { useLanguage } from "../../../../contexts/language.context"
import type { CallTimeRange } from "../../../../utils/callSharing"
import { ShareCallModalContent } from "../ShareCallModalContent"

type IShareCallModalProps = {
	open: boolean
	onClose: () => void
	callStartTimeSeconds: number
	callEndTimeSeconds: number
	publicAccessToken: string
	onPublicAccessTokenDidUpdate: (newToken: string) => void
	callId: string
}

export function ShareCallModal({
	open,
	onClose,
	callStartTimeSeconds,
	callEndTimeSeconds,
	callId,
	publicAccessToken,
	onPublicAccessTokenDidUpdate,
}: IShareCallModalProps) {
	const { t } = useLanguage()
	const allowedTimeRanges: CallTimeRange[] = React.useMemo(
		() => [{ startSec: callStartTimeSeconds, endSec: callEndTimeSeconds }],
		[callStartTimeSeconds, callEndTimeSeconds],
	)

	return (
		<Modal open={open} onClose={onClose}>
			<div className="text-lg font-semibold">
				{t("Share call from")} <Duration seconds={callStartTimeSeconds} /> {t("to")}{" "}
				<Duration seconds={callEndTimeSeconds} />
			</div>
			<ShareCallModalContent
				publicAccessToken={publicAccessToken}
				onPublicAccessTokenDidUpdate={onPublicAccessTokenDidUpdate}
				callId={callId}
				allowedTimeRanges={allowedTimeRanges}
			/>
		</Modal>
	)
}
