import { InformationCircleIcon } from "@heroicons/react/20/solid"
import { PlayIcon } from "@heroicons/react/24/outline"
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import assertNever from "assert-never"
import clsx from "clsx"
import React from "react"

import type {
	ObjectionBadge,
	ObjectionExtract,
	ObjectionReportSection,
} from "../../../../core/application/gateways/objections.gateway"
import type { Call, CallObjection } from "../../../../core/domain/Call.entity"
import { type TFunction, type TranslationKey, useLanguage } from "../../../contexts/language.context"
import { makeCallPath } from "../../../router/Router"
import { Badge } from "../../design-system/Badge.component"
import { Button } from "../../design-system/Button.component"
import { Tooltip } from "../../design-system/Tooltip.component"
import { TrackingButton } from "../../design-system/TrackingButton.component"
import { TrackingLink } from "../../design-system/TrackingLink.component"
import { TranscriptionQuote } from "../TranscriptionQuote.component"

function riskToText(riskLevel: CallObjection["riskLevel"]): TranslationKey {
	switch (riskLevel) {
		case "high":
			return "High risk"
		case "medium":
			return "Medium risk"
		case "low":
			return "Low risk"
	}
}

function riskTooltip(riskLevel: CallObjection["riskLevel"], t: TFunction): string {
	switch (riskLevel) {
		case "high":
			return t(
				"High risk of blocking sales, based on the prospect’s descriptions, sentiments, and the quality of the objection handling.",
			)
		case "medium":
			return t(
				"Medium risk of blocking sales, based on the prospect’s descriptions, sentiments, and the quality of the objection handling.",
			)
		case "low":
			return t(
				"Low risk of blocking sales, based on the prospect’s descriptions, sentiments, and the quality of the objection handling.",
			)
	}
}

type Step = "response-analysis" | "recommendations" | "call-snippets"
const allSteps: Step[] = ["response-analysis", "recommendations", "call-snippets"] as const

function ObjectionNavigation({
	currentStep,
	onStepChange,
	steps,
}: {
	steps: Step[]
	currentStep: Step
	onStepChange: (newStep: Step) => void
}) {
	return (
		<nav aria-label="Progress" className="flex items-center justify-center">
			<ol role="list" className="ml-8 flex items-center space-x-5">
				{steps.map((step) => (
					<li key={step}>
						{step === currentStep ? (
							<div aria-current="step" className="relative flex items-center justify-center">
								<span aria-hidden="true" className="absolute flex h-5 w-5 p-px">
									<span className="h-full w-full rounded-full bg-indigo-200" />
								</span>
								<span
									aria-hidden="true"
									className="relative block h-2.5 w-2.5 rounded-full bg-indigo-600"
								/>
							</div>
						) : (
							<TrackingButton
								onClick={() => onStepChange(step)}
								eventName={`Objection navigation clicked}`}
								eventProperties={{
									newStep: step,
								}}
								className="block h-2.5 w-2.5 rounded-full bg-gray-200 hover:bg-gray-400"
							/>
						)}
					</li>
				))}
			</ol>
		</nav>
	)
}

function ObjectionReportExtract({ extract }: { extract: ObjectionExtract }) {
	const { t } = useLanguage()
	const [showMore, setShowMore] = React.useState(false)
	const content = extract.content
	return (
		<div className="mt-2 rounded-r-md bg-gray-100 text-gray-800 px-2 py-2 text-sm border-l-2 border-gray-400">
			<div className="text-sm font-medium text-gray-900 flex items-center">
				<TrackingLink
					to={makeCallPath(extract.callId, undefined)}
					className="bg-gray-100 text-indigo-600 p-1.5 rounded-sm inline-flex text-xs mr-2 gap-1.5"
					eventName="Transcription quote link to call clicked"
					eventProperties={{
						callId: extract.callId,
					}}
				>
					<PlayIcon className="w-4 h-4" />
					<span>{extract.callName}</span>
				</TrackingLink>
				{extract.attendeeName}
			</div>

			{content.length > 200 ? (
				showMore ? (
					<>
						{content}
						<span>
							<Button
								size="xs"
								variant="text"
								onClick={() => setShowMore(false)}
								eventName="Show less clicked"
							>
								{t("Show less")}
							</Button>
						</span>
					</>
				) : (
					<>
						{content.slice(0, 200)}...
						<span>
							<Button
								size="xs"
								variant="text"
								onClick={() => setShowMore(true)}
								eventName="Show more clicked"
							>
								{t("Show more")}
							</Button>
						</span>
					</>
				)
			) : (
				content
			)}
		</div>
	)
}

function badgeToText(badge: ObjectionBadge): TranslationKey {
	switch (badge) {
		case "new":
			return "New"
		case "frequency-increase":
			return "Frequency increase"
		default:
			assertNever(badge)
	}
}

export function ObjectionsRow({
	objection,
	call,
	isDefaultCollapsed,
	className,
	sectionIndex,
	totalCallsRecordedInReportRange,
	hidePercentIncrease,
}: {
	objection: CallObjection | ObjectionReportSection
	call?: Call
	isDefaultCollapsed?: boolean
	className?: string
	sectionIndex: number
	totalCallsRecordedInReportRange?: number
	hidePercentIncrease?: boolean
}) {
	const [isCollapsed, setIsCollapsed] = React.useState(isDefaultCollapsed ?? false)
	const [currentStep, setStep] = React.useState<Step>("response-analysis")
	const { t } = useLanguage()
	const steps: Step[] = React.useMemo(() => {
		if (!("extracts" in objection) || !objection.extracts?.length) {
			return ["response-analysis", "recommendations"] as const
		}

		return allSteps
	}, [objection])
	const [showMoreExtracts, setShowMoreExtracts] = React.useState(false)
	const extracts = objection.extracts ?? []
	const extractsToShow = showMoreExtracts ? extracts : extracts?.slice(0, 3)

	const canGoToNextStep = steps.indexOf(currentStep) < steps.length - 1
	const canGoToPreviousStep = steps.indexOf(currentStep) > 0

	const handleGoToNextStep = React.useCallback(() => {
		if (canGoToNextStep) {
			setStep(steps[steps.indexOf(currentStep) + 1])
		}
	}, [canGoToNextStep, currentStep, steps, setStep])

	const handleGoToPreviousStep = React.useCallback(() => {
		if (canGoToPreviousStep) {
			setStep(steps[steps.indexOf(currentStep) - 1])
		}
	}, [canGoToPreviousStep, currentStep, steps, setStep])

	const toggleCollapse = React.useCallback(() => {
		setIsCollapsed((prev) => !prev)
	}, [setIsCollapsed])

	const uniqueExtractsCallIds = React.useMemo(() => {
		if (!("extracts" in objection) || !objection.extracts) {
			return []
		}

		return objection.extracts
			.map((extract) => ("callId" in extract ? extract.callId : null))
			.filter((callId): callId is string => callId !== null)
	}, [objection])

	const badges = "badges" in objection && objection.badges.length > 0 && objection.badges ? objection.badges : null

	return (
		<div
			key={objection.title}
			className={clsx("border border-gray-200 rounded-lg relative shadow-md", badges && "mt-3", className)}
		>
			{badges && (
				<Badge
					label={
						<div className="flex items-center justify-center gap-1.5">
							{badgeToText(badges.includes("new") ? "new" : badges[0])}
							<span className="relative flex h-1.5 w-1.5">
								<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
								<span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-green-500"></span>
							</span>
						</div>
					}
					variant="success"
					className="absolute left-[-16px] top-0"
				/>
			)}
			<div className="flex flex-row p-6">
				<div className="flex-1">
					<div className="flex justify-between">
						<div className="flex items-baseline gap-2">
							<p className="font-semibold text-navy-700 text-md">
								{sectionIndex + 1}. {objection.title}
							</p>
							<span
								className={clsx(
									"flex items-center gap-1 text-sm font-semibold italic",
									objection.riskLevel === "high" && "text-orange-500",
									objection.riskLevel === "medium" && "text-yellow-500",
									objection.riskLevel === "low" && "text-green-500",
								)}
							>
								{t(riskToText(objection.riskLevel))}
								<Tooltip tooltipClassName="max-w-xs" content={riskTooltip(objection.riskLevel, t)}>
									<InformationCircleIcon className="h-4 w-4 text-gray-400 ml-1" />
								</Tooltip>
							</span>
						</div>
						<div className="flex items-center ml-2 justify-center gap-2">
							{"callsCount" in objection && objection.callsCount && !hidePercentIncrease && (
								<span className="text-xs text-emerald-500 font-medium">
									{objection.callsCount.percentIncrease}%{" "}
									{objection.callsCount.percentIncrease >= 0
										? t("increase in the last 7 days")
										: t("in the last 7 days")}
								</span>
							)}
							{totalCallsRecordedInReportRange && uniqueExtractsCallIds.length > 0 && (
								<span className="text-md text-indigo-500 font-medium mr-2">
									{uniqueExtractsCallIds.length}/{totalCallsRecordedInReportRange} {t("calls")}
								</span>
							)}
							<TrackingButton eventName="Objection toggle clicked" onClick={toggleCollapse}>
								{isCollapsed ? (
									<ChevronDownIcon className="h-5 w-5" />
								) : (
									<ChevronRightIcon className="h-5 w-5" />
								)}
							</TrackingButton>
						</div>
					</div>

					<p className="text-gray-400 font-medium italic mt-2 text-sm">{objection.description}</p>
				</div>
			</div>
			{isCollapsed && (
				<div className="border-t border-gray-200 p-3">
					<div className="flex flex-row">
						<div className="flex items-center justify-center">
							<TrackingButton
								eventName="Objection step navigation clicked"
								onClick={handleGoToPreviousStep}
								className={clsx(
									"rounded-full border border-indigo-500 p-0.5 text-indigo-500",
									!canGoToPreviousStep && "invisible",
								)}
							>
								<ChevronLeftIcon className="h-5 w-5" />
							</TrackingButton>
						</div>
						<div className="flex-1">
							{currentStep === "response-analysis" && (
								<div className="mx-2">
									<p className="text-gray-900 font-semibold text-md">{t("Response analysis")}</p>
									<div className="border-l-2 border-gray-400 pl-2 mt-2">
										<p className="text-gray-900 font-medium text-sm">
											{objection.responseAnalysis}
										</p>
									</div>
								</div>
							)}

							{currentStep === "recommendations" && (
								<div className="mx-2">
									<p className="text-gray-900 font-semibold text-md">{t("Recommendations")}</p>
									<div className="border-l-2 border-gray-400 pl-2 mt-2">
										<ul className="list-disc list-inside text-gray-900 font-medium text-sm">
											{objection.recommendations.map((recommendation) => (
												<li key={recommendation}>{recommendation}</li>
											))}
										</ul>
									</div>
								</div>
							)}
							{currentStep === "call-snippets" && extractsToShow && extractsToShow.length > 0 && (
								<>
									{extractsToShow.map((extract, i) => (
										<div key={i} className="mx-2 mt-2">
											{"quote" in extract ? (
												call ? (
													<TranscriptionQuote
														rawQuote={extract.quote ?? ""}
														call={call}
														trackingComponent="suggestions"
													/>
												) : null
											) : (
												<ObjectionReportExtract extract={extract} />
											)}
										</div>
									))}
									{extracts.length && extracts.length > 3 && (
										<div className="mt-2 flex items-center justify-center">
											<Button
												size="xs"
												variant="default"
												onClick={() => setShowMoreExtracts((prev) => !prev)}
												eventName="Show more extracts clicked"
											>
												{showMoreExtracts ? t("Show less") : t("Show more")}
											</Button>
										</div>
									)}
								</>
							)}
							<div className="mt-4">
								<ObjectionNavigation currentStep={currentStep} onStepChange={setStep} steps={steps} />
							</div>
						</div>
						<div className="flex items-center justify-center">
							<TrackingButton
								eventName="Objection step navigation clicked"
								onClick={handleGoToNextStep}
								className={clsx(
									"rounded-full border border-indigo-500 p-0.5 text-indigo-500",
									!canGoToNextStep && "invisible",
								)}
							>
								<ChevronRightIcon className="h-5 w-5" />
							</TrackingButton>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
