import React, { useState } from "react"
import { useMount } from "react-use"

import type { CreateHighlightParams } from "../../../../../core/application/gateways/library.gateway"
import { Call } from "../../../../../core/domain/Call.entity"
import { MouseDragRangeIndicator } from "../../../../components/design-system/VideoPlayer/_MouseDragRangeIndicator.component"
import { TimelineCursor } from "../../../../components/design-system/VideoPlayer/_TimelineCursor.component"
import { useCallVideoPlayer } from "../../../../contexts/callVideoPlayer.context"
import { useDependencies } from "../../../../contexts/dependencies.context"
import { useLibrary } from "../../../../hooks/useLibrary"
import { CreateCallExcerptModal } from "./CreateCallExcerptModal.component"
import { CreateCallHighlightModal } from "./CreateCallHighlightModal.component"
import { ShareCallModal } from "./ShareCallModal.component"

type CallTimeRangeOptions = "create-call-highlight" | "share-call" | "create-call-excerpt"

export type CreateCallTimeRangeFormStateProps =
	| {
			type: "none"
	  }
	| {
			type: "range-dragged"
			startTimeSeconds: number
			endTimeSeconds: number
	  }
	| {
			type: "range-confirmed"
			startTimeSeconds: number
			endTimeSeconds: number
			selectedOption: CallTimeRangeOptions
	  }

export type CreateCallTimeRangeFormProps = {
	createCallTimeRangeForm: CreateCallTimeRangeFormStateProps
	totalDurationSeconds: number
	currentTimeSeconds: number
	containerRef: React.RefObject<HTMLDivElement>
	onCreateCallTimeRangeFormReset: () => void
	onCreateCallHighlightRangeConfirm: () => void
	onShareCallRangeConfirm: () => void
	onCallHighlightMetadataSubmit: (metadata: { containingLibraryFolderId: string; comment: string }) => Promise<void>
	children?: React.ReactNode
	onStartTimeChange: (newStartTime: number) => void
	onEndTimeChange: (newEndTime: number) => void
	refreshHighlights?: () => void
}

export function CreateCallTimeRangeForm({
	createCallTimeRangeForm,
	totalDurationSeconds,
	containerRef,
	onCreateCallTimeRangeFormReset,
	onCallHighlightMetadataSubmit,
	children,
	onStartTimeChange,
	onEndTimeChange,
	refreshHighlights,
}: CreateCallTimeRangeFormProps) {
	const { library, fetchLibrary } = useLibrary()
	const { call } = useCallVideoPlayer()

	useMount(async () => {
		await fetchLibrary()
	})

	const handleDragStartTime = React.useCallback(
		(event: MouseEvent) => {
			if (createCallTimeRangeForm.type !== "range-dragged" || !containerRef.current) {
				return
			}

			const boundingWidth = containerRef.current.getBoundingClientRect().width ?? 0
			const relativePosition = event.clientX - (containerRef.current.getBoundingClientRect().left ?? 0)
			const startTimeSeconds = Math.max((relativePosition / boundingWidth) * totalDurationSeconds, 0)
			if (startTimeSeconds >= createCallTimeRangeForm.endTimeSeconds) {
				return
			}

			onStartTimeChange(startTimeSeconds)
		},
		[containerRef, createCallTimeRangeForm, totalDurationSeconds, onStartTimeChange],
	)

	const handleDragEndTime = React.useCallback(
		(event: MouseEvent) => {
			if (createCallTimeRangeForm.type !== "range-dragged" || !containerRef.current) {
				return
			}

			const boundingWidth = containerRef.current.getBoundingClientRect().width ?? 0
			const relativePosition = event.clientX - (containerRef.current.getBoundingClientRect().left ?? 0)
			const endTimeSeconds = Math.min(
				(relativePosition / boundingWidth) * totalDurationSeconds,
				totalDurationSeconds,
			)
			if (endTimeSeconds <= createCallTimeRangeForm.startTimeSeconds) {
				return
			}

			onEndTimeChange(endTimeSeconds)
		},
		[containerRef, createCallTimeRangeForm, totalDurationSeconds, onEndTimeChange],
	)

	if (createCallTimeRangeForm.type === "none") {
		return children
	}

	const handleMetadataSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
		event.preventDefault()
		const formData = new FormData(event.currentTarget)
		const containingLibraryFolderId = formData.get("containingLibraryFolderId")?.valueOf()
		if (typeof containingLibraryFolderId !== "string") {
			throw new Error("Containing library folder is required") // should not happen
		}
		const comment = formData.get("comment")?.valueOf()

		if (typeof comment !== "string" || comment.trim() === "") {
			throw new Error("Comment is required")
		}

		return onCallHighlightMetadataSubmit({ containingLibraryFolderId, comment })
	}

	return (
		<>
			{createCallTimeRangeForm.type !== "range-confirmed" && (
				<>
					<TimelineCursor
						variant="active"
						currentTimeSeconds={createCallTimeRangeForm.startTimeSeconds}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
						onDrag={handleDragStartTime}
					/>
					<TimelineCursor
						variant="active"
						currentTimeSeconds={createCallTimeRangeForm.endTimeSeconds}
						totalDurationSeconds={totalDurationSeconds}
						containerRef={containerRef}
						onDrag={handleDragEndTime}
					/>
				</>
			)}
			{createCallTimeRangeForm.type !== "range-confirmed" && (
				<MouseDragRangeIndicator
					mouseDragStartTimeSeconds={createCallTimeRangeForm.startTimeSeconds}
					currentHoverTimeSeconds={createCallTimeRangeForm.endTimeSeconds}
					totalDurationSeconds={totalDurationSeconds}
					containerRef={containerRef}
				/>
			)}
			<CreateCallExcerptModal
				open={
					createCallTimeRangeForm.type === "range-confirmed" &&
					createCallTimeRangeForm.selectedOption === "create-call-excerpt"
				}
				onClose={onCreateCallTimeRangeFormReset}
				callExceptStartTimeSeconds={createCallTimeRangeForm.startTimeSeconds}
				callExceptEndTimeSeconds={createCallTimeRangeForm.endTimeSeconds}
				callId={call.props.id}
				refreshHighlights={refreshHighlights}
			/>
			<CreateCallHighlightModal
				open={
					createCallTimeRangeForm.type === "range-confirmed" &&
					createCallTimeRangeForm.selectedOption === "create-call-highlight"
				}
				onClose={onCreateCallTimeRangeFormReset}
				callHighlightStartTimeSeconds={createCallTimeRangeForm.startTimeSeconds}
				callHighlightEndTimeSeconds={createCallTimeRangeForm.endTimeSeconds}
				library={library}
				onSubmit={handleMetadataSubmit}
				onReset={onCreateCallTimeRangeFormReset}
			/>
			{call instanceof Call && call.props.publicAccessToken && (
				<ShareCallModal
					open={
						createCallTimeRangeForm.type === "range-confirmed" &&
						createCallTimeRangeForm.selectedOption === "share-call"
					}
					onClose={onCreateCallTimeRangeFormReset}
					callStartTimeSeconds={createCallTimeRangeForm.startTimeSeconds}
					callEndTimeSeconds={createCallTimeRangeForm.endTimeSeconds}
					callId={call.props.id}
					publicAccessToken={call.props.publicAccessToken}
					onPublicAccessTokenDidUpdate={(newToken) => {
						call.props.publicAccessToken = newToken
					}}
				/>
			)}
		</>
	)
}

export function useCreateCallTimeRangeForm(callId: string | undefined, refreshHighlights: (() => void) | undefined) {
	const { libraryFoldersGateway } = useDependencies()
	const [state, setState] = useState<CreateCallTimeRangeFormStateProps>({ type: "none" })

	const resetTimeRangeForm = () => {
		setState({ type: "none" })
	}

	const setTimeRange = (state: { startTimeSeconds: number; endTimeSeconds: number }) => {
		setState({ type: "range-dragged", ...state })
	}

	const confirmTimeRange = (
		state: { startTimeSeconds: number; endTimeSeconds: number },
		selectedOption: CallTimeRangeOptions,
	) => {
		setState({
			type: "range-confirmed",
			startTimeSeconds: state.startTimeSeconds,
			endTimeSeconds: state.endTimeSeconds,
			selectedOption,
		})
	}

	const createCallHighlight = async (metadata: { containingLibraryFolderId: string; comment: string }) => {
		/**
		 * Only calls with an id can create highlights.
		 * Public calls (with no id) cannot.
		 */
		if (typeof callId === "undefined") {
			return
		}

		if (state.type !== "range-confirmed") {
			return
		}

		const createHighlightParams: CreateHighlightParams = {
			callId,
			startsAtMs: Math.round(state.startTimeSeconds * 1000),
			endsAtMs: Math.round(state.endTimeSeconds * 1000),
			comment: metadata.comment,
			containingLibraryFolderId: metadata.containingLibraryFolderId,
		}

		await libraryFoldersGateway.createHighlight(createHighlightParams)
		refreshHighlights?.()
	}

	return {
		timeRangeState: state,
		resetTimeRangeForm,
		setTimeRange,
		confirmTimeRange,
		createCallHighlight,
	}
}
