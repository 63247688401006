import clsx from "clsx"

import { INTESCIA_WORKSPACE_ID } from "../../../../config"
import type { Call } from "../../../../core/domain/Call.entity"
import type { TagType } from "../../../../core/domain/TagType.entity"
import type { User } from "../../../../core/domain/User.entity"
import { useAuthenticatedSession } from "../../../contexts/authentication.context"
import { useLanguage } from "../../../contexts/language.context"
import type { ListCallsReason } from "../../../hooks/useCalls/types"
import { _CallRow } from "./_CallRow.component"
import { _CallRowSkeleton } from "./_CallRowSkeleton.component"

export type CallsTableProps = {
	refreshCalls?: (reason: ListCallsReason) => Promise<void>
	calls: Call[]
	displayAssignedUser: boolean
	users?: User[]
	hideOverallScore?: boolean
	showLoadingSkeleton?: boolean
	className?: string
	tableContainerClassName?: string
	availableTags: TagType[] | "loading"
}

export function CallsTable({
	calls,
	displayAssignedUser,
	users,
	refreshCalls,
	hideOverallScore,
	showLoadingSkeleton,
	className,
	tableContainerClassName,
	availableTags,
}: CallsTableProps) {
	const { t } = useLanguage()
	const { user, workspace } = useAuthenticatedSession()

	const scoreLabel = workspace?.id === INTESCIA_WORKSPACE_ID ? "overall" : "MEDDIC"
	const shouldHideMeddicColumn = Boolean(scoreLabel === "MEDDIC" && workspace.props.preferences?.hideMeddicScoring)
	const displayScoreColumn = !hideOverallScore && !user.isIntesciaCSM() && !shouldHideMeddicColumn

	return (
		<>
			<div className={clsx("mt-8 flow-root", className)}>
				<div className="overflow-x-auto">
					<div className="inline-block min-w-full py-2 align-middle px-0.5">
						<div
							className={clsx(
								"overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg mb-1",
								tableContainerClassName,
							)}
						>
							<table className="min-w-full divide-y divide-gray-300">
								<thead className="bg-gray-50">
									<tr>
										<th
											scope="col"
											className="py-3.5 pl-2 pr-3 text-left text-sm font-semibold text-gray-900"
										>
											{t("Name")}
										</th>
										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											{t("Company")}
										</th>
										{displayScoreColumn && (
											<th
												scope="col"
												className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
											>
												{scoreLabel === "overall" ? t("Score") : "MEDDIC"}
											</th>
										)}

										<th
											scope="col"
											className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
										>
											{t("Tags")}
										</th>

										{displayAssignedUser && (
											<th
												scope="col"
												className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
											>
												{t("User")}
											</th>
										)}
										{user.isWorkspaceManager() && <th />}
									</tr>
								</thead>
								<tbody className="divide-y divide-gray-200 bg-white">
									{showLoadingSkeleton
										? [...Array(5).keys()].map((_, index) => <_CallRowSkeleton key={index} />)
										: calls.map((call) => (
												<_CallRow
													key={call.props.id}
													call={call}
													displayAssignedUser={displayAssignedUser}
													displayScore={displayScoreColumn ? scoreLabel : undefined}
													users={users}
													refreshCalls={refreshCalls}
													availableTags={availableTags}
												/>
										  ))}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
