import clsx from "clsx"
import React from "react"

import type { ObjectionReport } from "../../../core/application/gateways/objections.gateway"
import type { Team } from "../../../core/domain/Team.entity"
import { Input } from "../../components/design-system/Input"
import { SpinnerIcon } from "../../components/design-system/SpinnerIcon.component"
import { TrackingButton } from "../../components/design-system/TrackingButton.component"
import { Layout } from "../../components/shared/Layout/Layout.component"
import { ObjectionsRow } from "../../components/shared/Objections/ObjectionRow.component"
import { useAuthenticatedSession } from "../../contexts/authentication.context"
import { useDependencies } from "../../contexts/dependencies.context"
import { useLanguage } from "../../contexts/language.context"
import { useWorkspaceUsers } from "../../contexts/workspace-users.context"
import { computeFilterOptions } from "../../hooks/useCalls/compute-filter-options"
import { ObjectionReportModal } from "./components/ObjectionReportModal.component"

function getLatestReportMonthly(reports: ObjectionReport[] | null): Record<string, ObjectionReport | null> {
	if (!reports?.length) {
		return {}
	}

	const reportsByMonth: Record<string, ObjectionReport | null> = {}
	const sortedReports = reports.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
	const earliestReport = sortedReports[sortedReports.length - 1]
	const latestReport = sortedReports[0]

	const monthsBetweenFirstAndLast: Date[] = []
	const currentMonth = new Date(earliestReport.createdAt)
	const lastMonth = new Date(latestReport.createdAt)
	while (currentMonth <= lastMonth) {
		monthsBetweenFirstAndLast.push(new Date(currentMonth))
		currentMonth.setMonth(currentMonth.getMonth() + 1)
	}

	const sortedMonths = monthsBetweenFirstAndLast.sort((a, b) => b.getTime() - a.getTime())
	for (const month of sortedMonths) {
		const reportForMonth = sortedReports.find(
			(report) => new Date(report.createdAt).getMonth() === month.getMonth(),
		)
		reportsByMonth[month.toISOString()] = reportForMonth || null
	}

	return reportsByMonth
}

export type SelectedReportEntry = {
	date: Date
	report: ObjectionReport | null
}

type ObjectionsTeamFilterProps = {
	selectedTeamId: string
	onSelectedTeamIdChange: (teamId: string) => void
	disabled?: boolean
}

function ObjectionsTeamFilter({ selectedTeamId, onSelectedTeamIdChange, disabled }: ObjectionsTeamFilterProps) {
	const { teams, user } = useAuthenticatedSession()
	const { t } = useLanguage()
	const { workspaceUsers } = useWorkspaceUsers()
	const selectedTeam = selectedTeamId ? teams.find((team) => team.id === selectedTeamId) ?? null : null
	const filterOptions = computeFilterOptions(user, teams, workspaceUsers ?? [], selectedTeam)

	const handleSelectedTeamFilterChange = React.useCallback(
		(team: Team | null) => {
			onSelectedTeamIdChange(team?.id ?? "#any#")
		},
		[onSelectedTeamIdChange],
	)

	return (
		<>
			<Input.Label htmlFor="team-filter">{t("Teams")}</Input.Label>
			<Input.Select
				id="team-filter"
				name="team-filter"
				onChange={(event) => {
					const value = event.target.value
					const team = teams.find((team) => team.id === value)
					if (!team && value !== "#any#") {
						console.error("Team not found")
						return
					}
					handleSelectedTeamFilterChange(team || null)
				}}
				value={selectedTeamId}
				className="!w-auto"
				disabled={disabled}
			>
				<Input.SelectOption value="#any#">{t("All teams")}</Input.SelectOption>
				{filterOptions.teams.options.map((team) => (
					<Input.SelectOption key={team.id} value={team.id}>
						{team.name}
					</Input.SelectOption>
				))}
			</Input.Select>
		</>
	)
}

function HighImpactObjections() {
	const { objectionsGateway } = useDependencies()
	const [allObjectionReports, setAllObjectionReports] = React.useState<ObjectionReport[] | null>(null)
	const { t } = useLanguage()
	const [selectedReport, setSelectedReport] = React.useState<SelectedReportEntry | null>(null)
	const [selectedTeamId, setSelectedTeamId] = React.useState<string>("#any#")
	const [isLoading, setIsLoading] = React.useState(true)

	React.useEffect(() => {
		setIsLoading(true)
		objectionsGateway
			.getAllReports({
				teamId: selectedTeamId === "#any#" ? undefined : selectedTeamId,
			})
			.then(setAllObjectionReports)
			.finally(() => setIsLoading(false))
	}, [objectionsGateway, selectedTeamId])

	const monthlyReports = getLatestReportMonthly(allObjectionReports)
	const latestReport = Object.values(monthlyReports)[0]
	const monthlyReportsWithoutLatest = Object.fromEntries(
		Object.entries(monthlyReports).filter(([month]) => month !== Object.keys(monthlyReports)[0]),
	)
	const hasMonthlyReports = Object.values(monthlyReportsWithoutLatest).length > 0

	if (!allObjectionReports) {
		return (
			<div className="flex items-center justify-center overflow-hidden">
				<SpinnerIcon className={"w-8 h-8 text-gray-500"} />
			</div>
		)
	}

	return (
		<div>
			{selectedReport && (
				<ObjectionReportModal
					objectionReportEntry={selectedReport}
					handleClose={() => setSelectedReport(null)}
				/>
			)}

			<div className="flex justify-between flex-row mb-4">
				<div>
					<h2 className="text-xl font-semibold text-gray-800">{t("High impact objections")}</h2>
					<p className="text-md text-gray-500 font-medium mt-1">
						{t(
							"The objections which are the most frequently cited by your prospects and have the highest risk of blocking your sales in the last 30 days.",
						)}
					</p>
				</div>
				<div>
					<ObjectionsTeamFilter
						disabled={isLoading}
						selectedTeamId={selectedTeamId}
						onSelectedTeamIdChange={setSelectedTeamId}
					/>
				</div>
			</div>
			{isLoading ? (
				<div className="overflow-hidden flex items-center justify-center">
					<SpinnerIcon className={"w-8 h-8 text-gray-500"} />
				</div>
			) : (
				<>
					{latestReport ? (
						<div className="mt-8">
							<div className="flex items-start">
								<div className="flex-1 flex flex-col gap-2">
									{latestReport.sections.map((section, i) => (
										<ObjectionsRow
											sectionIndex={i}
											className="flex-1"
											key={section.title}
											objection={section}
											isDefaultCollapsed={i === 0}
											totalCallsRecordedInReportRange={latestReport.callsCount}
										/>
									))}
								</div>
								<div className="shadow-md border border-gray-200 rounded-lg p-4 bg-white w-48 ml-4">
									<h3 className="text-md font-semibold text-gray-800 mb-2">{t("Monthly reports")}</h3>
									{hasMonthlyReports ? (
										Object.entries(monthlyReportsWithoutLatest).map(([month, report]) => (
											<TrackingButton
												eventName="Report month selected"
												eventProperties={{ month }}
												key={month}
												onClick={() => setSelectedReport({ date: new Date(month), report })}
												className={clsx(
													"text-sm font-medium  flex items-center gap-2",
													report
														? "text-gray-600 hover:text-gray-900 font-semibold underline"
														: "text-gray-400 hover:text-gray-500",
												)}
											>
												<span
													className={clsx(
														"relative flex h-1.5 w-1.5",
														report?.id !== latestReport?.id && "invisible",
													)}
												>
													<span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
													<span className="relative inline-flex rounded-full h-1.5 w-1.5 bg-green-500"></span>
												</span>
												{new Date(month).toLocaleString("default", {
													month: "long",
													year: "numeric",
												})}
											</TrackingButton>
										))
									) : (
										<p className="text-sm text-gray-500">
											{t(
												"Your first monthly report will appear here at the end of the month, as long as you have recorded a minimum of 6 calls throughout the month.",
											)}
										</p>
									)}
								</div>
							</div>
						</div>
					) : (
						<div className="flex items-start">
							<div className="flex-1 border border-gray-200 rounded-lg relative shadow-md p-6 text-center">
								<h2 className="text-xl font-semibold text-gray-800">
									{t("Record your first 6 calls to reveal your principal objections")}
								</h2>
								<p className="text-sm text-gray-500 mt-2">
									{t(
										"Discover which objections have the biggest impact on your sales by recording a minimum of 6 calls.",
									)}
								</p>
								<p className="text-sm text-gray-500">
									{t("And don’t stop there - the more calls, the more accurate the analysis!")}
								</p>
							</div>
							<div className="shadow-md border border-gray-200 rounded-lg p-4 bg-white w-48 ml-4">
								<h3 className="text-md font-semibold text-gray-800 mb-2">{t("Monthly reports")}</h3>
								<p className="text-sm text-gray-500">
									{t("Record your first calls to access your monthly objection reports.")}
								</p>
							</div>
						</div>
					)}
				</>
			)}
		</div>
	)
}

export function HighImpactObjectionsPage() {
	return (
		<Layout pageName="Objection reports" className="!py-2" isFluid={true}>
			<div className="mt-4">
				<HighImpactObjections />
			</div>
		</Layout>
	)
}
