import assertNever from "assert-never"
import { useCallback, useEffect, useState } from "react"
import { useMount } from "react-use"

import type { CalendarAndEvents } from "../../core/application/gateways/calendar-events.gateway"
import { type CalendarEvent } from "../../core/domain/CalendarEvent.entity/CalendarEvent.entity"
import { getRecordingStatusDetails, RecordingStatus } from "../../core/domain/CalendarEvent.entity/RecordingStatus"
import { useDependencies } from "../contexts/dependencies.context"
import { eventBus, Events } from "./eventBus"

export function useCalendarIntegration() {
	const [calendarAndEvents, setCalendarAndEvents] = useState<{ data: CalendarAndEvents | undefined; error: boolean }>(
		{
			data: undefined,
			error: false,
		},
	)

	const { calendarEventsGateway } = useDependencies()

	const fetchEvents = useCallback(
		async (reason: string) => {
			console.log(`Listing calendar events because: ${reason}`)
			try {
				const calendar = await calendarEventsGateway.list({ filter: "forthcoming" })
				setCalendarAndEvents((prevCalendar) => {
					if (eventRecordingDidBecomeAvailable(prevCalendar.data, calendar)) {
						eventBus.dispatchEvent(new Event(Events.CalendarEventRecordingDidBecomeAvailable))
					}
					return { data: calendar, error: false }
				})
			} catch (e) {
				console.error("fetchEvents failed:", e)
				setCalendarAndEvents({ data: undefined, error: true })
			}
		},
		[calendarEventsGateway],
	)

	/**
	 * TODO: Error management
	 * OAuth app will redirect to https://api.rippletide.com
	 * If the session expired, the backend will show a raw text message "Authentication token is required" instead of redirecting to the login page
	 * This wil happen with both connect and reconnect
	 */
	const reconnectWorkCalendar = () => {
		if (calendarAndEvents.data?.calendar.status !== "disconnected") return
		switch (calendarAndEvents.data.calendar.platform) {
			case "microsoft_outlook":
				window.location.href = calendarAndEvents.data.calendar.microsoftOutlookOAuthUrl
				break

			case "google_calendar":
				window.location.href = calendarAndEvents.data.calendar.googleCalendarOAuthUrl
				break

			default:
				assertNever(calendarAndEvents.data.calendar.platform)
		}
	}

	const deleteWorkCalendar = async () => {
		await calendarEventsGateway.deleteCalendar()
		await fetchEvents("calendar-deleted")
	}

	useMount(() => fetchEvents("mount"))

	useEffect(() => {
		function getRefreshFrequency(): { reason: string; refreshFrequencySec: number } | undefined {
			if (calendarAndEvents.error) {
				// possible improvement: incremental frequency to avoid high load on server when it is down
				// return { reason: "autorefresh-error", refreshFrequencySec: 30 } // 30 seconds
				return
			}
			if (!calendarAndEvents.data) {
				// xhr pending, do not refresh
				return undefined
			}

			const isConnectedButNotSynced =
				calendarAndEvents.data.calendar.status === "connected" && !calendarAndEvents.data.calendar.wasEverSynced

			switch (calendarAndEvents.data.calendar.status) {
				case "connected":
					if (isConnectedButNotSynced) {
						return { reason: "calendar-syncing", refreshFrequencySec: 2 } // 2 seconds
					}
					if (shouldRefreshOften(calendarAndEvents.data.calendarEvents, new Date())) {
						// return { reason: "fast-refresh", refreshFrequencySec: 5 } // 5 seconds
						return
					}
					// return { reason: "autorefresh", refreshFrequencySec: 30 } // 30 seconds
					return

				case "connecting":
					return { reason: "calendar-connecting", refreshFrequencySec: 2 } // 2 seconds

				case "disconnected":
					// user should click on 'link calendar' in order to reconnect
					// return { reason: "calendar-disconnected", refreshFrequencySec: 30 } // coach could still be used
					return

				case "not-found":
					// user should click on 'link calendar'
					// return { reason: "calendar-not-found", refreshFrequencySec: 30 } // coach could still be used
					return

				default:
					assertNever(calendarAndEvents.data.calendar)
			}
		}

		const refresh = getRefreshFrequency()
		if (!refresh) {
			return
		}

		const intervalId = setInterval(() => fetchEvents(refresh.reason), refresh.refreshFrequencySec * 1000)
		return () => clearInterval(intervalId)
	}) // cannot cache because 'shouldRefreshOften' is not predictible

	return {
		calendarAndEvents,
		fetchEvents,
		reconnectWorkCalendar,
		deleteWorkCalendar,
	}
}

// TODO: could be moved to something like CalendarEntity.checkNewEventRecordingAvailable(prevCalendar)
function eventRecordingDidBecomeAvailable(prevCal: CalendarAndEvents | undefined, newCal: CalendarAndEvents): boolean {
	return newCal.calendarEvents.some((event) => {
		// Recording is available
		if (event.botStatus.code !== RecordingStatus.Done_RecordingAvailable) {
			return false
		}

		// Recording was not available previously
		return prevCal?.calendarEvents.some((prevEvent) => {
			return prevEvent.id === event.id && prevEvent.botStatus.code !== RecordingStatus.Done_RecordingAvailable
		})
	})
}

// TODO: could be moved to something like CalendarEntity.areEventsProneToChange()
function shouldRefreshOften(events: CalendarEvent[], now: Date): boolean {
	return events.some((event) => {
		return getRecordingStatusDetails(event.botStatus.code, {
			botWillJoinAt: event.botStatus.botWillJoinAt,
			eventStartTime: event.startTime,
			eventHasEnded: event.endTime < now,
			now,
		}).fastRefresh
	})
}
